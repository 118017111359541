<template>
  <div>
    <v-data-table
      fixed-header
      class="elevation-1"
      loading-text="数据加载中... 请稍等"
      calculate-widths
      :server-items-length="totalDesserts"
      :options.sync="options"
      style="white-space: nowrap;"
      :headers="headers"
      :items="desserts"
      :loading="loading"
      :search="search"
      :footer-props="{
        itemsPerPageOptions: [25, 50, 50, 100],
        itemsPerPageAllText: '全部',
        itemsPerPageText: '每页行数',
        showFirstLastPage: true,
      }"
      :show-overflow-tooltip="true"
      @contextmenu:row="show"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>{{ $route.meta.title }}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="搜索"
            single-line
            hide-details
            style='max-width: 239px;'
            clearable
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            scrollable
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                新建项
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-divider style="margin: 0 1rem;"></v-divider>
              <v-card-text style="max-height: calc(100vh - 15rem);">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row>
                      <template v-if="($route.path.substr(1) === 'AuthGroup')">
                        <template v-for="(header, i) in headers">
                          <v-col cols="12" :key='i' v-if="['group_name'].includes(header.value)">
                            <v-text-field
                              v-model="editedItem[header.value]"
                              :label="header.text"
                              :rules="header.required ? [v => required(v, header.text)] : []"
                            ></v-text-field>
                          </v-col>
                        </template>

                        <v-col cols="12" md="6" v-for="item in authUnit" :key="item.auth_num">
                          <v-switch
                            v-model="editedItem['auth_nums']"
                            inset
                            color="primary"
                            :label="`${item.auth_num} ${item.auth_desc}`"
                            :value="item.auth_num"
                            multiple
                            required
                          ></v-switch>
                        </v-col>
                      </template>
                      <template v-else>
                        <v-col cols="12" sm="6"
                          v-for="(header, i) in filterHeader"
                          :key='i'
                        >
                          <v-select
                            v-if="header.select"
                            :items="header['select-item']"
                            item-text="text"
                            item-value="value"
                            v-model="editedItem[header.value]"
                            menu-props="auto"
                            :label="header.text"
                            :rules="header.required ? [v => required(v, header.text)] : []"
                          ></v-select>
                          <v-textarea
                            v-else-if="header.value === 'memo'"
                            outlined
                            v-model="editedItem[header.value]"
                            :label="header.text"
                            :rules="header.required ? [v => required(v, header.text)] : []"
                          >
                          </v-textarea>
                          <v-text-field
                            v-else
                            v-model="editedItem[header.value]"
                            :label="header.text"
                            :rules="header.required ? [v => required(v, header.text)] : []"
                          ></v-text-field>
                        </v-col>
                      </template>

                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-divider style="margin: 0 1rem;"></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  取消
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5" style="flex-direction: column;">你确定要删除该项吗？</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">取消</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">确认</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!-- 显示处理 -->
      <template v-for="key in handler_keys" #[`item.${key}`]="{ item }">
        {{ getText(key, item[key]) }}
      </template>

      <template #body="{ items }">
        <tbody>
          <tr
            v-for="item in items"
            :key="item.id"
          >
            <template
              v-for="key, i in headers"
            >
              <td
                :key="i"
                v-if="key['value'] != 'actions'"
                v-text="getText(key['value'], item[key['value']])"
                v-bind="textTruncateAttrs(item[key.value])"
              >
              </td>
              <td :key="i" v-else>
                <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
              </td>
            </template>
          </tr>
        </tbody>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
      </template>

      <template #no-data>
        <v-btn color="primary" @click="initialize">刷新</v-btn>
      </template>
      <!-- 显示处理结束 -->
    </v-data-table>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item link @click="editItem()">
          <v-list-item-icon style="margin-right: 1rem;">
            <v-icon>mdi-pencil</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="mr-5">编辑</v-list-item-title>
        </v-list-item>
        <v-divider class="mx-2"></v-divider>
        <v-list-item link @click="deleteItem()">
          <v-list-item-icon style="margin-right: 1rem;">
            <v-icon>mdi-delete</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="mr-5">删除</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import { diff } from '../plugins/utils'
import http from '../plugins/http'
  export default {
    data: () => ({
      valid: false,
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: '',
      totalDesserts: 0,
      options: {},
      showMenu: false,
      x: 0,
      y: 0,
      search: '',
      loading: true,
      dialog: false,
      dialogDelete: false,
      headers: [],
      desserts: [],
      editedIndex: -1,
      currentItem: {},
      editedItem: {},
      defaultItem: {},
      authUnit: [],
      handler_keys: []
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? '新建' : '编辑'
      },
      filterHeader () {
        return this.headers.filter(h => (
          !['id', 'update_date', 'actions'].includes(h.value)
        ))
      },
    },

    watch: {
      options: {
        handler () {
          this.initialize()
        },
        deep: true,
      },
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      search () {
        this.initialize()
      }
    },

    created () {
      this.getHeaders()
    },

    methods: {
      getText (keyName, key) {
        for (const i in this.headers) {
          let header = this.headers[i]
          if (header.value == keyName && header['select-item']) {
            return getOption(header['select-item'], key)
          }
        }
        return key
      },

      show (e, item) {
        this.currentItem = item.item
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.$nextTick(() => {
          this.showMenu = true
        })
      },

      getHeaders () {
        http.get("/table/get_headers", {params: {'name': this.$route.path.substring(1)}})
          .then(response => {
            let _handler_key = [] // 根据字段是否可选来设置关系映射
            this.headers = response.data.map((item) => {
              if (item.select) _handler_key.push(item.value)
              item.text = item.text.split("：")[0]
              return item
            })
            this.headers.push({ text: '操作', value: 'actions', sortable: false })
            this.handler_keys = _handler_key
          })
          .catch(this.catchError)
        this.setAuthGroup()
      },

      setAuthGroup (item) {
        if (this.$route.path.substring(1) !== "AuthGroup") {return}
        this.defaultItem['auth_nums'] = []
        let post = (func, params) => {
          http.get("/get/auth_info", {params: params})
            .then(func)
            .catch(this.catchError)
        }
        if (item) {
          setTimeout(() => {
            this.editedItem['auth_nums'] = item.auth_nums.split(',')
          }, 39)
        } else {
          post(response => {
            this.authUnit = response.data
          })
        }
      },

      initialize () {
        this.getDesserts().then(data => {
          this.desserts = data.items
          this.totalDesserts = this.search ? data.display_total : data.total
          this.loading = false
        })
      },

      getDesserts () {
        return new Promise((resolve) => {
          http.get("/table/get_data", {params: {
            ...this.options,
            name: this.$route.path.substring(1),
            search: this.search || null
          }})
            .then(response => {
              let items = response.data
              const total = response.total
              resolve({
                items,
                total,
              })
            })
        })
      },

      editItem (item) {
        item = item || this.currentItem
        this.editedIndex = this.desserts.indexOf(item)
        if (this.authUnit.length > 0) {
          this.setAuthGroup(item)
        }
        this.editedItem = Object.assign({}, item)
        this.dialog = true
        this.$refs.form && this.$refs.form.resetValidation()
      },

      deleteItem (item) {
        item = item || this.currentItem
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.postData("/table/delete_data", {
          id: this.editedItem['id']
        }).then(() => {
          this.totalDesserts -= 1
          this.desserts.splice(this.editedIndex, 1)
          this.closeDelete()
          this.showSnackBar('删除成功')
        }).catch(this.catchError)
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.$refs.form.resetValidation()
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          const edit_data = diff(this.editedItem, this.desserts[this.editedIndex])
          if (Object.keys(edit_data).length === 0) {
            this.close()
            return
          }
          if (this.$refs.form.validate()) {
           this.postData("/table/set_data", edit_data, this.editedItem.id)
            .then(() => {
              Object.assign(this.desserts[this.editedIndex], this.editedItem)
              this.close()
            })
            .catch(this.catchError)
          }
        } else {
          if (this.$refs.form.validate()) {
            this.postData("/table/creat_data", this.editedItem)
              .then(data => {
                this.desserts.push(data.data)
                this.showSnackBar('新增成功')
                this.totalDesserts += 1
                this.close()
              })
              .catch(this.catchError)
          }
        }
      },

      postData (url, data, id) {
        return http.post(url, {data, id, name: this.$route.path.substring(1)})
      },

      showSnackBar (text) {
        this.snackbar_text = text
        this.snackbar = true
        setTimeout(() => {
          this.snackbar_text = ''
        }, this.snackbar_timeout)
      },

      catchError (e) {
        this.$alert(e.msg || e.response.data.msg, "error")
      },

      required (val, text) {
        return !!val || `${text}还没填呢~`
      },

      textTruncateAttrs(text) {
        const condition = text && (text?.length > 100)
        return {
          class: { 'td-truncate':  condition},
          title: condition ? text : null
        }
      }
    },
  }
  function getOption (obj_list, key) {
    return new Map(obj_list.map((item) => ([item.value, item.text]))).get(key)
  }
</script>

<style>
  .v-data-table__wrapper {
    max-height: calc(100vh - 220px);
  }
  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .td-truncate {
    max-width: 500px;
    white-space: nowrap!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
  }
</style>
